import visaLogo from '../assets/images/visa-logo.svg';
import planetBg from '../assets/images/planet-bg.jpg';
import facebookLogo from '../assets/images/facebook-logo.svg';
import googleLogo from '../assets/images/google-logo.svg';
import signInImage from '../assets/images/menu_image.svg';
import signInBg from '../assets/images/sign-modal-bg.jpg';
import logoBlack from '../assets/images/new-ui/logo-dark.svg';
import beforeEdit from '../assets/images/image-editing/before-editing.jpg';
import afterEdit from '../assets/images/image-editing/after-editing.jpg';
import planetShipBg from '../assets/images/plant-ship-bg.jpg';
import burnCashBg from '../assets/images/burncash.jpeg';
import hourglassBg from '../assets/images/hourglass.jpeg';
import manPortraitBg from '../assets/images/man-portrait.jpeg';
import rtgImageBg from '../assets/images/rtg-image.png';
import rtgPanelBg from '../assets/images/rtg-panel-bg.png';
import mindBlubBg from '../assets/images/mind-blub.png';
import artististBg from '../assets/images/artistist.png';
import supernova from '../assets/images/trusted-by/supernova.png';
import grassJelly from '../assets/images/trusted-by/grass-jelly.png';
import ultrablue from '../assets/images/trusted-by/ultrablue.png';
import hawkPackaging from '../assets/images/trusted-by/hawk-packaging.png';
import dental from '../assets/images/trusted-by/dental.png';
import Jonny from '../assets/images/testimonials/Jonny.jpeg';
import Ivan from '../assets/images/testimonials/Ivan.jpeg';
import Chris from '../assets/images/testimonials/Chris.jpeg';
import sultan from '../assets/images/testimonials/sultan.webp';
import sophia from '../assets/images/testimonials/sophia.png';
import sukriti from '../assets/images/testimonials/sukriti.jpeg';
import quotes from '../assets/images/quotes.svg';
import contest from '../assets/images/contest.png';
import error from '../assets/images/404-bg.jpg';
import lockedImg from '../assets/images/locked.svg';
import whiteBlueLogo from '../assets/images/new-ui/logo-light.svg';
import bgDemoMain from '../assets/images/new-ui/bg-demo.jpg';
import heroBg from '../assets/images/new-ui/hero-bg.png';
import discover1 from '../assets/images/new-ui/discover1.jpg';
import discover2 from '../assets/images/new-ui/discover2.jpg';
import discover3 from '../assets/images/new-ui/discover3.jpg';
import discover4 from '../assets/images/new-ui/discover4.jpg';
import discover5 from '../assets/images/new-ui/discover5.jpg';
import discover6 from '../assets/images/new-ui/discover6.jpg';
import discover7 from '../assets/images/new-ui/discover7.jpg';
import discover8 from '../assets/images/new-ui/discover8.jpg';
import discover9 from '../assets/images/new-ui/discover9.jpg';
import discover10 from '../assets/images/new-ui/discover10.jpg';
import discover11 from '../assets/images/new-ui/discover11.jpg';
import discover12 from '../assets/images/new-ui/discover12.jpg';
import discover13 from '../assets/images/new-ui/discover13.jpg';
import discover14 from '../assets/images/new-ui/discover14.jpg';
import discover15 from '../assets/images/new-ui/discover15.jpg';
import discover16 from '../assets/images/new-ui/discover16.jpg';
import signCover from '../assets/images/sign-up-cover-pic.jpg';
import contactUs from '../assets/images/contactUs.jpg';
import homepage from '../assets/images/new-ui/Homepage.jpeg';
import hero_bgNew_1 from '../assets/images/new-ui/Hero-bgNew-1.jpg';
import hero_bgNew_2 from '../assets/images/new-ui/Hero-bgNew-2.png';
import category_1 from '../assets/images/new-ui/category-1.jpg';
import category_2 from '../assets/images/new-ui/category-2.jpg';
import category_3 from '../assets/images/new-ui/category-3.jpg';
import category_4 from '../assets/images/new-ui/category-4.jpg';
import category_5 from '../assets/images/new-ui/category-5.jpg';
import category_6 from '../assets/images/new-ui/category-6.jpg';

import discoverItem_1 from '../assets/images/new-ui/discoverItem/discoverItem-1.jpeg';
import discoverItem_2 from '../assets/images/new-ui/discoverItem/discoverItem-2.jpeg';
import discoverItem_3 from '../assets/images/new-ui/discoverItem/discoverItem-3.jpeg';
import discoverItem_4 from '../assets/images/new-ui/discoverItem/discoverItem-4.jpeg';
import discoverItem_5 from '../assets/images/new-ui/discoverItem/discoverItem-5.jpeg';
import discoverItem_6 from '../assets/images/new-ui/discoverItem/discoverItem-6.jpeg';
import discoverItem_7 from '../assets/images/new-ui/discoverItem/discoverItem-7.jpeg';
import discoverItem_8 from '../assets/images/new-ui/discoverItem/discoverItem-8.jpeg';

import loadingGIF from '../assets/images/loading.gif';

import checkout_logo from '../assets/images/new-ui/C_logo.png';

const Icons = {
  visaLogo,
  planetBg,
  facebookLogo,
  googleLogo,
  signInImage,
  signInBg,
  logoBlack,
  beforeEdit,
  afterEdit,
  planetShipBg,
  burnCashBg,
  hourglassBg,
  manPortraitBg,
  rtgImageBg,
  rtgPanelBg,
  mindBlubBg,
  artististBg,
  supernova,
  grassJelly,
  ultrablue,
  hawkPackaging,
  dental,
  Jonny,
  Ivan,
  Chris,
  sultan,
  sophia,
  sukriti,
  quotes,
  contest,
  error,
  lockedImg,
  whiteBlueLogo,
  bgDemoMain,
  heroBg,
  discover1,
  discover2,
  discover3,
  discover4,
  discover5,
  discover6,
  discover7,
  discover8,
  discover9,
  discover10,
  discover11,
  discover12,
  discover13,
  discover14,
  discover15,
  discover16,
  signCover,
  contactUs,
  homepage,
  hero_bgNew_1,
  hero_bgNew_2,
  category_1,
  category_2,
  category_3,
  category_4,
  category_5,
  category_6,

  discoverItem_1,
  discoverItem_2,
  discoverItem_3,
  discoverItem_4,
  discoverItem_5,
  discoverItem_6,
  discoverItem_7,
  discoverItem_8,

  loadingGIF,

  checkout_logo,
};

export default Icons;
