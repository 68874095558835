import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Paths from '../../router/paths';
import { Image } from 'react-bootstrap';
import Icons from '../../utils/Icons';
import Dropdown from 'react-bootstrap/Dropdown';
import Cookies from 'js-cookie';
import { GlobalContext } from '../../contexts/GlobalState';
import HeaderExploreMenu from './header-items/header-explore-menu';
import GetUnlimitedBtn from './header-items/get-unlimited-btn';
import CreateImageBtn from './header-items/create-image-btn';
import ProfileMenu from './header-items/profile-menu';
import HeaderSearch from './header-search';
import { handleLogout } from '../../utils/basic-common';
import * as teamsService from '../../services/teams-service';

const SecondaryHeader = () => {
  const router = useLocation();
  const navigate = useNavigate();
  const { profile, active_package } = useContext(GlobalContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [accessToken, setAccessToken] = useState(false);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Track window width

  const [teamsList, setTeamsList] = React.useState({});

  // team list
  useEffect(() => {
    (async function () {
      try {
        const res = await teamsService.fetchTeamsList();
        if (!res.data.status) {
          throw res.data.message;
        }
        setTeamsList(res.data.data.team);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    // Update window width when resized
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log(router.pathname);
  }, [router]);

  useEffect(function () {
    var cookie = Cookies.get('access_token');
    if (typeof cookie !== 'undefined' && cookie.length) {
      setAccessToken(cookie);
    }
  }, []);

  useEffect(() => {
    if (
      router.pathname === Paths.root ||
      router.pathname === Paths.discover ||
      router.pathname === Paths.recentSearches
    ) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setIsHeaderSticky(true);
    }
  }, [router]);
  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition >= 100) {
      setIsHeaderSticky(true);
    }
    // Temporary fix to test header text color
    else if (router.pathname === Paths.pricing) {
      setIsHeaderSticky(true);
    } else {
      setIsHeaderSticky(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setSearchToggle(false);
  };

  const navigateFromSideMenu = (path) => {
    setIsMenuOpen(false);
    navigate(Paths[path]);
  };
  const handleSearchToggle = () => {
    setSearchToggle(!searchToggle);
    setIsMenuOpen(false);
  };

  return (
    <Fragment>
      <header className={`header-box ${isHeaderSticky ? 'sticky' : ''}`}>
        <div className="header-wrap">
          <div className="header-left">
            <Link to={Paths.root}>
              <Image
                src={isHeaderSticky ? Icons.logoBlack : Icons.whiteBlueLogo}
                alt="..."
              />
            </Link>
            {router.pathname.includes('images') ||
            router.pathname === Paths.collections ||
            router.pathname === Paths.account ||
            router.pathname === Paths.pricing ? (
              <HeaderSearch searchToggle={searchToggle} />
            ) : (
              ''
            )}
          </div>
          <div className="header-right">
            {router.pathname.includes('images') ||
            router.pathname === Paths.collections ||
            router.pathname === Paths.account ||
            router.pathname === Paths.pricing ||
            router.pathname === Paths.root ? (
              <button
                className="btn icon-btn search-toggle-btn heading14 d-md-none"
                onClick={handleSearchToggle}
              >
                <span className="icon-search-alt"></span>
              </button>
            ) : (
              ''
            )}

            {windowWidth < 991 &&
              windowWidth > 575 &&
              (!accessToken || !active_package) && (
                <GetUnlimitedBtn navigateFromSideMenu={navigateFromSideMenu} />
              )}

            <div
              className={`submenu ${accessToken ? 'logged-in' : ''} ${
                isMenuOpen ? 'active' : ''
              }`}
            >
              {windowWidth < 575 && (!accessToken || !active_package) && (
                <GetUnlimitedBtn navigateFromSideMenu={navigateFromSideMenu} />
              )}
              {accessToken && active_package && (
                <CreateImageBtn navigateFromSideMenu={navigateFromSideMenu} />
              )}
              {/* {accessToken && (
                <div className="header-link">
                  <Link to="#">
                    <span className="text-primary me-2">
                      {profile?.current_daily_count}
                    </span>
                    Tokens
                  </Link>
                </div>
              )} */}
              <div className="header-link">
                <button
                  className="btn p-0 border-0"
                  onClick={() => navigateFromSideMenu('pricing')}
                >
                  Pricing
                </button>
              </div>
              <div className="header-link">
                <button
                  className="btn p-0 border-0"
                  onClick={() => navigateFromSideMenu('discover')}
                >
                  Discover
                </button>
              </div>
              <hr className="w-100 my-1" />
              {accessToken ? (
                <Fragment>
                  <div className="header-link">
                    <button
                      className="btn p-0 border-0"
                      onClick={() => navigateFromSideMenu('collections')}
                    >
                      My Collections
                    </button>
                  </div>
                  <div className="header-link">
                    <button
                      className="btn p-0 border-0"
                      onClick={function () {
                        setIsMenuOpen(false);
                        navigate(`${Paths.account}?tab=downloads`);
                      }}
                    >
                      My Downloads
                    </button>
                  </div>
                  <div className="header-link">
                    <button
                      className="btn p-0 border-0"
                      onClick={() => navigateFromSideMenu('account')}
                    >
                      My Account
                    </button>
                  </div>
                  {Object.keys(teamsList)?.length > 0 ? (
                    <div className="header-link">
                      <button
                        className="btn p-0 border-0"
                        onClick={() => navigateFromSideMenu('#')}
                      >
                        My team
                      </button>
                    </div>
                  ) : (
                    <div className="header-link">
                      <button
                        className="btn p-0 border-0"
                        onClick={() => navigateFromSideMenu('#')}
                      >
                        Create team
                      </button>
                    </div>
                  )}
                  <hr className="w-100 my-1" />
                  <div className="header-link">
                    <button
                      className="btn p-0 border-0"
                      onClick={() => navigateFromSideMenu('root')}
                    >
                      Help Center
                    </button>
                  </div>
                  <div className="header-link">
                    <button className="btn p-0 border-0" onClick={handleLogout}>
                      Log Out
                    </button>
                  </div>
                </Fragment>
              ) : (
                <div className="header-link mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => navigateFromSideMenu('signIn')}
                  >
                    Sign in
                  </button>
                </div>
              )}
            </div>
            <div className="d-none align-items-center d-lg-flex">
              <HeaderExploreMenu />
              <div className="header-link">
                <Link to={Paths.pricing}>Pricing</Link>
              </div>
              {/* {accessToken && (
                <div className="header-link d-lg-block d-none">
                  <Link to="#">
                    <span className="icon-tokens"></span>
                    <span className="mx-1">{profile?.current_daily_count}</span>
                    Tokens
                  </Link>
                </div>
              )} */}
              {accessToken &&
              (profile?.profile_type_name === 'Visionary' ||
                profile?.profile_type_name === 'Explorer') ? (
                '' // <CreateImageBtn navigateFromSideMenu={navigateFromSideMenu} />
              ) : (
                <GetUnlimitedBtn navigateFromSideMenu={navigateFromSideMenu} />
              )}
            </div>

            {(windowWidth > 991 || !accessToken) &&
              (accessToken ? (
                <ProfileMenu />
              ) : (
                <div className="header-link ps-3 d-lg-block d-none">
                  <Link to={Paths.signIn} className="d-flex align-items-center">
                    <span className="icon-user-logo me-1"></span>Sign In
                  </Link>
                </div>
              ))}
            <button
              className="btn icon-btn border-0 d-lg-none"
              onClick={toggleMenu}
            >
              <span
                className={`${isMenuOpen ? 'icon-close' : 'icon-menu-alt'}`}
              ></span>
            </button>
          </div>
        </div>
      </header>
    </Fragment>
  );
};
export default SecondaryHeader;
