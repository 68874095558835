import React, { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Image } from 'react-bootstrap';
import Icons from '../../utils/Icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Paths from '../../router/paths';
import Cookies from 'js-cookie';
import DiscoverItem from '../../components/discover-item/discover-item';
import { fetchImageStyles } from '../../services/images-service';
import SearchSection from '../../components/search-section/search-section';
import DiscoverCategory from '../../partials/home/discover-category';
import ActivitiesTabs from '../../partials/home/activities-tabs';
import toast from 'react-hot-toast';
import HomepageItem from '../../partials/home/homepage-items';

const Aistock = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [stylesList, setStylesList] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState({});

  useEffect(function () {
    var cookie = Cookies.get('access_token');
    if (typeof cookie !== 'undefined' && cookie.length) {
      setAccessToken(cookie);
    }
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const res = await fetchImageStyles();
        if (!res.data.status) {
          throw res.data.message;
        }
        // console.log(res.data.data);
        setStylesList(res.data.data);
        setSelectedStyle(res.data.data[0]);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  const handleRedirection = (e) => {
    if (e.key === 'Enter') {
      console.log(selectedStyle?.id);
      navigate(
        `${Paths.searchResult}?search=${searchData}&selectedId=${selectedStyle?.id}`
      );
      // window.location.reload();
    }
  };

  const handleSearchByImage = () => {
    toast('coming soon');
  };

  return (
    <Fragment>
      <section className="global-search-box">
        <img src={Icons.homepage} className="main-banner-bg" alt="..." />
        <div className="container">
          <div className="global-search-wrap">
            <p className="heading10 search-keyword">
              This Search:{' '}
              <span className="semi-bold">
                Sun rising over majestic norwegian winter landscape, cinematic
              </span>
            </p>
            <p className="heading10 photo-credit">
              Photo by
              <span className="semi-bold"> BentoboxC </span>
            </p>
            <div className="mx-850">
              <h3 className="heading28">
                Amazing imagery created by you
                <span className="text-primary">.</span>
              </h3>
              <div className="global-search-inner">
                <div className="">
                  <Dropdown className="link-dropdown2">
                    <Dropdown.Toggle id="dropdown-basic">
                      <span className="icon-game d-md-none"></span>
                      <span className="d-none d-md-block">
                        {' '}
                        {selectedStyle?.style === 'Photorealism'
                          ? 'All Styles'
                          : selectedStyle?.style}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {stylesList.map((item, index) => (
                        <Dropdown.Item
                          as="button"
                          key={index}
                          onClick={() => setSelectedStyle(item)}
                        >
                          {item.style}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="global-searchbox">
                  <input
                    className="form-control"
                    type="text"
                    value={searchData}
                    onChange={(e) => setSearchData(e.target.value)}
                    onKeyPress={handleRedirection}
                    placeholder="Search for your image"
                  />
                  <Link
                    to={`${Paths.signUp}?search=${searchData}&selectedId=${selectedStyle?.id}`}
                    className="btn icon-btn p-0"
                  >
                    <span className="icon-search-alt"></span>
                  </Link>
                </div>
                <div className="hide-search">
                  <button
                    className="btn btn-secondary d-flex align-items-center"
                    onClick={handleSearchByImage}
                  >
                    <span className="icon-frame me-2"></span>
                    Search by image
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {accessToken ? (
        <Fragment>
          <section className="btns-navigations top-btns">
            <div className="container">
              <div>
                <ActivitiesTabs />
              </div>
            </div>
          </section>
        </Fragment>
      ) : (
        <Fragment>
          <section className="hero-section-box">
            <div className="container">
              <div className="info-top">
                <div className="hero-section-wrap">
                  <div className="hero-section-left">
                    <img
                      src={Icons.hero_bgNew_1}
                      alt="..."
                      className="fit-contain"
                    />
                  </div>
                  <div className="hero-section-right">
                    <h3 className="heading38">
                      If you can imagine it, you will find it on Cascaid
                    </h3>
                    <p className="heading22">
                      Describe your idea and watch it come alive.{' '}
                    </p>
                    <Link
                      className="btn btn-light-secondary line-height-1"
                      to={Paths.signIn}
                    >
                      Try for free
                    </Link>
                  </div>
                </div>
              </div>
              <div className="info-bottom">
                <div className="hero-section-wrap">
                  <div className="hero-section-left">
                    <h3 className="heading38">
                      Say goodbye to wasting your time searching for the perfect
                      image.{' '}
                    </h3>
                    <p className="heading22">
                      Cascaid's unshackles your team from time-consuming
                      searches, empowering them to create stunning images in
                      seconds.{' '}
                    </p>
                    <Link
                      className="btn btn-light-secondary line-height-1"
                      to={Paths.signIn}
                    >
                      Learn more
                    </Link>
                  </div>

                  <div className="hero-section-right">
                    <img
                      src={Icons.hero_bgNew_2}
                      alt="..."
                      className="fit-contain"
                    />
                  </div>
                </div>
              </div>
              <div className="trustedby-box">
                <p className="heading14">Trusted By</p>
                <div className="trustby-wrap">
                  <div className="trusted-by-col">
                    <div className="trusted-by-item">
                      <img
                        src={Icons.supernova}
                        alt="..."
                        className="fit-contain"
                      />
                    </div>
                  </div>
                  <div className="trusted-by-col">
                    <div className="trusted-by-item">
                      <img
                        src={Icons.grassJelly}
                        alt="..."
                        className="fit-contain"
                      />
                    </div>
                  </div>
                  <div className="trusted-by-col">
                    <div className="trusted-by-item">
                      <img
                        src={Icons.ultrablue}
                        alt="..."
                        className="fit-contain"
                      />
                    </div>
                  </div>
                  <div className="trusted-by-col">
                    <div className="trusted-by-item">
                      <img
                        src={Icons.hawkPackaging}
                        alt="..."
                        className="fit-contain"
                      />
                    </div>
                  </div>
                  <div className="trusted-by-col">
                    <div className="trusted-by-item">
                      <img
                        src={Icons.dental}
                        alt="..."
                        className="fit-contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*Discover items*/}
          <HomepageItem linkText="SEE MORE" linkUrl={Paths.discover} />

          {/* <DiscoverItem
            data={imageDetails}
            title="Discover images"
            linkText="SEE MORE"
            linkUrl={Paths.discover}
          /> */}
        </Fragment>
      )}

      {/*Discover by category*/}
      <DiscoverCategory />
    </Fragment>
  );
};
export default Aistock;
